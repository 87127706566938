// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$control-contractor-portal-ui-primary: mat.define-palette(mat.$indigo-palette);
$control-contractor-portal-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$control-contractor-portal-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$control-contractor-portal-ui-theme: mat.define-light-theme((color: (primary: $control-contractor-portal-ui-primary,
        accent: $control-contractor-portal-ui-accent,
        warn: $control-contractor-portal-ui-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($control-contractor-portal-ui-theme);

/* You can add global styles to this file, and also import other style files */

$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import '~material-design-icons-iconfont/src/material-design-icons';

@import './assets/stylesheet/variables';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap');


// Tailwind starts
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
// Tailwind ends


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-stack!important;
  color: #444;
}
div, h1, h2, h3, h4, h5, h6, 
.mat-h4, .mat-subheading-1, .mat-typography .mat-h4, .mat-typography .mat-subheading-1, .mat-typography h4,
.mat-h1, .mat-headline, .mat-typography .mat-h1, .mat-typography h1,
.mat-h2, .mat-typography .mat-h2, .mat-typography h2,
.mat-h3, .mat-typography .mat-h3, .mat-typography h3,
.mat-h5, .mat-typography .mat-h5, .mat-typography h5,
.mat-h6, .mat-typography .mat-h6, .mat-typography .mat-headline, .mat-typography h6{
  font-family: $font-stack!important;
}
.main-container{
  background-color: $background-base-color;
}
.full-width {
  width: $full-width;
}

.heading1 {
  font-size: 17px !important;
  // font-weight: 500 !important;
  // border-bottom: 1px solid #efefef;
  // background-color: #f2f2f2;
  // padding: 8px 10px;
  // border-radius: 4px;
  font-family: $font-stack!important;
}

.heading2 {
  font-size: 17px;
}

.heading3 {
  font-size: 15px !important;
  // font-weight: 500 !important;
}

.example-form-field {
  font-size: 12px;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: $primary-color;
}

.card1 {
  background-color: $white;
  padding: 20px;
  border-radius: 4px;
  // height: calc(100vh - 135px);
  height: calc(100vh - 146px);
  overflow-y: auto;
}
.card1Franchisee {
  background-color: $white;
  padding: 20px;
  border-radius: 4px;
  // height: calc(100vh - 135px);
  height: calc(100vh - 35px);
  overflow-y: auto;
}

.mat-raised-button {
  box-shadow: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex:hover {
  outline: none !important;
  border: transparent !important;
}


.breadcrumb {
  border-bottom: 1px solid #eee;
}

.breadcrumb h4 {
  margin-bottom: 10px;
  text-align: center;
}

.breadcrumb .b-left {
  display: $dinline-flex;
  cursor: $pointer;
}

.breadcrumb .b-left span {
  padding-top: 3px;
}

.breadcrumb .b-left .mat-icon {
  width: auto;
  height: auto;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ddd !important;
}
.link{
  color: $primary-color;
  cursor: pointer;
}
.text-bold{
  font-weight: 500;
}
.label2{
  font-weight: 500;
  padding-left: 10px;
}
.pointer{
  cursor: pointer;
}
::ng-deep .login-form .example-form-field{
  font-size: 14px;
}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding-left: 4px!important;
  margin-top: 2px!important;
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin-bottom: 2px!important;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #0061A3!important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0061A3!important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
  background-color: #0061A3!important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: #0061A3!important;
}
// .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick, .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
//   color: #f44336!important;
// }
@media screen and (max-width: 575px) {
  .card1 {
    padding: 15px;
  }
  .card1Franchisee {
    padding: 15px;
  }
  .breadcrumb h4 {
    font-size: 14px !important;
    margin-bottom: 8px;
    margin-top: -27px;
  }
  .breadcrumb .b-left {
    font-size: 12px;
    padding-top: 1px;
    position: relative;
    z-index: 9999;
  }
  .breadcrumb .b-left span {
    padding-top: 1px;
  }
  .breadcrumb .b-left .mat-icon {
    font-size: 20px;
  }
}

.mat-simple-snackbar-action .mat-button-wrapper{
  color: #0378b9;
}
.noDataBox img{
  max-width: 450px;
}
.no-data{
  font-size: 25px;
  font-weight: 600;
  color: #999;
  margin-top: 15px!important;
  text-transform: uppercase;
}
.scheduleSection{
  border: 2px solid #ccc;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 16px;
}
.fw-500{
  font-weight: 500;
}


@media screen and (max-width: 575px) {
  .noDataBox img{
    max-width:100%;
  }
}